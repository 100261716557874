<template>
	<b-form @submit.stop.prevent="onSubmit" @keyup="onChange" :novalidate="true">
    <b-card no-body class="shadow-lg" border-variant="primary">
        <div slot="header" v-if="!isEdit">
          <strong>DeviceGroup Form</strong>
        </div>
        <b-card-body>
         <!--  <b-form-group
            label="code"
            label-for="code"
            :label-cols="2"
            :invalid-feedback="form.errors.get('code')"
            :state="form.errors.has('code')"
            >
            <b-form-input disabled id="code" type="text" autocomplete="name" placeholder="Bedrijfs code"v-model="form.code">
            </b-form-input>
          </b-form-group> -->
          <b-form-group
            v-if="form.company"
            label="Company"
            label-for="companyname"
            :label-cols="2"
            :invalid-feedback="form.errors.get('name')"
            :state="form.errors.has('name')" 
            >
            <b-form-input disabled id="name" type="text" autocomplete="companyname" placeholder="Name of the company" v-model="form.company[0].name"></b-form-input>
          </b-form-group>
        </b-card-body>
        <b-table v-if="this.form.device_set" :items="form.device_set" selectable :fields="fields"></b-table>
        <b-card-footer>
          <b-button v-if="form.id" size="sm" variant="danger" v-b-modal="'confirm'+form.id"><i class="fa fa-trash" aria-hidden="true"></i> Remove Group</b-button>
          <b-modal 
            :id="'confirm'+form.id" 
            size="sm"
            :centered="true"
            button-size="lg"
            :hide-header="true"
            cancel-title="No"
            ok-title="Yes"
            auto-focus-button="cancel"
            @ok="onRemoveGroup"
            >
            Are you sure you want to remove this Device Group?
          </b-modal>
          <b-button v-if="isChange" class=" pull-right" type="submit" size="sm" variant="success"><i class="fa fa-check-circle-o" aria-hidden="true"></i> {{form.id? 'Edit':'Save and Continue'}}</b-button>
          <b-button v-if="isChange" class="pull-right mx-2" @click="onReset" size="sm" variant="warning"><i class="fa fa-backward"></i> Reset Form</b-button>
          <b-button v-if="isEdit" class=" pull-right" size="sm" variant="success" :to="`/devicegroup/device/${form.id}/add/`"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Add Device</b-button>
      </b-card-footer>
    </b-card>
  </b-form>
</template>
<script>
import Vue from "vue"
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import DeviceGroupSelect from './DeviceGroupSelect'
import DeviceModify from './DeviceModify'

export default AdminExtension.extend({
    name: 'DeviceGroupModify',
    props: {
      devicegroupId: {
        type: String,
        required: false,
      }
    },
    components: {
      DeviceGroupSelect
    },
    data() {
        return {
          isLocked: false,
            isSaved: true,
            companies: [],
            form: new Form({
                    id: null,
                    consistency_token: null,
                    code: null,
                    name: null,
                    status: null,
                    device_set: null,
                    company: null,
                }),
            isEdit: false,
            isChange: false,
            fields: [
              {key: 'name', label:'Name'},
              {key: 'uuid', label:'Uuid'},
              {key: 'status', label:'Status'},
              {key: 'expiry', label:'Expires on'},
            ]
        }
    },
    computed: {
    },
    methods: {
       ...mapActions("meds2go/device", ['deviceGroupDetail','deviceGroupRemove']),
      loadData(){
        if (this.devicegroupId > 0){
              this.deviceGroupDetail(this.devicegroupId).then(response => {
                // console.log(response)
                this.form.set(response)
              }).catch(errors => {
                console.log(errors)
              })
              this.isEdit = true
            }else {
              this.generateCode({generate_code:'group'}).then(response=> {
                this.form.code = response
              })
            }
      },
      onSubmit(){

      },
      onNewDevice(){
      },
      onReset(){
          this.form.reset()
      },
      onChange(){
          this.isChange = true
      },
      onRemoveGroup(){
          this.deviceGroupRemove(this.form.id)
      },
    },
    mounted() {
      this.loadData()
    }
})
</script>