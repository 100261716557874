<template>
	<b-row>
        <!-- <b-col v-if="!selectedDeviceGroup"sm="6">
          <DeviceGroupSelect :company-id="companyId" @devicegroup="onSelectedDevicegroup"/>
        </b-col> -->
        <b-col>
         <!--  <b-button v-if="!selectedDeviceGroup" variant="primary" size="md" @click="onNewGroup">New Device Group</b-button> -->
          <DeviceGroupModify :devicegroup-id="deviceGroupIdString"/>
        </b-col>
	</b-row>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import DeviceGroupSelect from './DeviceGroupSelect'
import DeviceGroupModify from './DeviceGroupModify'

export default AdminExtension.extend({
    name: 'DeviceGroupAdd',
    props: {
      companyId: {
        type: String,
        required: false,
      }
    },
    components: {
      DeviceGroupSelect,
      DeviceGroupModify
    },
    data() {
        return {
          devicegroup: {},
        }
    },
    computed: {
      selectedDeviceGroup(){
        return this.devicegroup.id > 0
      },
      deviceGroupIdString(){
        return this.devicegroup.id.toString()
      }
    },
    methods: {
        onNewGroup(){
          // Create new group modal
        },
        onSelectedDevicegroup(devicegroup){
          console.log(devicegroup)
          this.devicegroup = devicegroup
        },
        onReset(){
            this.form.reset()
        },
        onChange(){
            this.isChange = true
        },
    },
    mounted() {
    }
})
</script>