<template>
  <b-card-body>
    <div slot="header">
      <strong>Select DeviceGroup</strong>
    </div>
    <b-row>
    <b-col sm="8">
      <b-form-group
      label="Search on Device code or name"
      label-cols-sm="5"
      label-align-sm="right"
      label-size="sm"
      label-for="filterInput"
      >
        <b-input-group size="sm">
          <b-form-input
            ref="focus"
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="code or name"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col sm="4">
      <b-form-group
        label="Per page"
        label-cols-sm="4"
        label-align-sm="right"
        label-size="sm"
        label-for="perPageSelect"
      >
        <b-form-select
          v-model="perPage"
          id="perPageSelect"
          size="sm"
          :options="pageOptions"
          :plain="true"
        ></b-form-select>
      </b-form-group>
    </b-col>
  </b-row> 
  <b-table
      :current-page="currentPage"
      :per-page="perPage"
      :items="devicegroups"
      :fields="fields"
      :filter="filter"
      @filtered="onFiltered"
      :filter-included-fields="filterOn"
      selectable
      select-mode="single"
      @row-selected="onSelectedRow"
    >
      <template v-slot:cell(actions)="row">
        <b-button size="sm" variant="primary" @click="onDelete(row.item.id)" append><i class="fa fa-trash"></i> Delete</b-button>
      </template>
      <template v-slot:cell(total_devices)="row">
        {{row.item.device_set? row.item.device_set.length : "None"}}
      </template>
    </b-table>
    <b-row>
      <b-col sm=4></b-col>
      <b-col>
        <b-pagination
            v-if="pagination"
            v-model="currentPage"
            :total-rows="devicegroups.length"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
      <b-col sm=4></b-col>
    </b-row>
  </b-card>
  </b-card-body>
</template>
<script>
import {mapActions } from "vuex"
import AdminExtension from '../AdminExtension'

export default AdminExtension.extend({
    name: 'DeviceGroupSelect',
    props: {
      companyId: {
        require: false,
        type: String,
      }
    },
    components: {
    },
    data() {
        return {
          filter: null, 
          totalRows: null,
          perPage: 10, 
          pageOptions: [5,10,25,50],
          currentPage: 1,
          filterOn: ["code","name"],
          isEdit: false,
          isNew: false,

          devicegroups: [],
         fields: [
            {key: 'code', label:'Code', sortable: true},
            {key: 'name', label:'Name', sortable: true},
            {key: 'status', label:'Status', sortable: true},
            {key: 'total_devices', label:'Current Devices', sortable: true},
            // {key: 'actions', label:'Actions'},
            ],
        }
    },
    computed: {
      pagination(){
        return this.totalRows > this.perPage
      },
    },
    methods: {
        ...mapActions("meds2go/device", ['getCompanyDeviceGroups']),
        loadData(){
            this.getCompanyDeviceGroups(this.companyId).then(devicegroups => {
            this.devicegroups = devicegroups
            this.totalRows = devicegroups.length
          })
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        onSelectedRow(devicegroup){
          this.$emit('devicegroup', devicegroup[0])
        },
        onReset(){
        },
        onChange(){
        },
    },
    mounted() {
        this.loadData()
    }
})
</script>
